<script>
import {Bar} from "vue-chartjs";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-piechart-outlabels";
import {Chart as ChartJS} from 'chart.js'

ChartJS.defaults.global.defaultFontFamily = "Jost";

export default {
  extends: Bar,
  components: {ChartJSPluginDatalabels},
  props: ["labels", "data", "backgroundColor", "title", "is_money"],
  data() {
    return {
      myData: {
        labels: this.labels,
        datasets: [
          {
            label: this.title,

            backgroundColor: this.backgroundColor
                ? this.backgroundColor
                : "rgb(33, 150, 243)",
            data: this.data,
          },
        ],
      },
    };
  },

  methods: {
    formatter(value, context) {
      if (this.is_money) {
        return Intl.NumberFormat('us-US').format(value) + " DZD";
      } else {
        return value
      }
    },
    renderGraph() {
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                label: this.title,
                backgroundColor: this.backgroundColor ? this.backgroundColor : "rgba(33,150,243,0.85)",
                data: this.data,
              },
            ],
          },
          {
            responsive: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                align: 'end',
                anchor: 'end',
                borderRadius: 4,
                formatter: this.formatter,
                color: 'white',
                rotation: 0,
                backgroundColor: function (context) {
                  return context.dataset.backgroundColor;
                },
                padding: 4,
                value: function (context) {
                  return context + '%';
                },
              }
            },
          }
      );
    },
  },
  computed: {
    chartData: function () {
      return this.myData;
    },
  },
  watch: {
    data: function () {
      this.renderGraph();
    },
  },

  mounted() {
    this.renderGraph();
  },
};
</script>