<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <InteractionByTime
            axis="hour"
            :publicity="id"
            title="Intéractions par Heure"
          />
        </v-col>
        <v-col cols="6">
          <InteractionByTime
            axis="month"
            :publicity="id"
            title="Intéractions par Mois"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card class="rounded-lg flex d-flex flex-column" elevation="0">
            <v-card-title>Interactions</v-card-title>
            <v-card-text class="flex pa-0">
              <v-simple-table v-if="!isLoading && stats != null">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in stats" :key="key">
                      <td class="font-weight-medium">
                        <span>{{ key }}</span>
                      </td>
                      <td>{{ value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-col cols="12 py-5" v-else>
                <div class="text-center py-5">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="rounded-lg flex d-flex flex-column" elevation="0">
            <v-card-title class="text-center"
              >Interactions par Utilisateur</v-card-title
            >
            <v-card-text class="flex pa-0">
              <v-data-table
                v-if="!isLoading && stats != null"
                :items="details"
                :hide-default-header="true"
                :hide-default-footer="false"
                item-key="id"
                class="elevation-0"
                :items-per-page="10"
                :headers="getHeaders"
              >
                <template #header="{ props: { headers } }">
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        v-for="header in headers"
                        :key="header.value"
                        class="text-uppercase"
                        scope="col"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="text-align: left;">
                      {{ item.name }}
                    </td>
                    <td style="text-align: left;" v-for="action in actions" :key="'td-' + action">
                      {{ item[action] }}
                    </td>
                    <td style="text-align: left;">
                      {{ item["total"] }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-col cols="12 py-5" v-else>
                <div class="text-center py-5">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";
import InteractionByTime from "./InteractionByTime.vue";

export default {
  components: { InteractionByTime },
  data() {
    return {
      isLoading: false,
      data: null,
      stats: null,
      actions: [],
      total: 0,
      current_key: null,
      allTotal: 0,
      id: this.$route.params.id,
      headers: [
        { text: "Nom", value: "name",align: 'center', }
      ]
    };
  },
  methods: {
    getStatistique() {
      this.isLoading = true;

      HTTP.get("V2/publicities/tracks/" + this.$route.params.id)
        .then((res) => {
          // console.log(res.data.data)
          this.details = res.data.data.details;
          this.actions = res.data.data.actions;
          this.stats = res.data.data.stats;

          this.headers=  [
            { text: "Nom", value: "name" }
          ];

          this.actions.forEach(e => {
            this.headers.push({ text: e, value: e})
          });

          this.headers.push({ text: "Total", value: "total"})

          this.isLoading = false;
        })
        .catch((err) => {
          // this.$errorMessage = err.response.data.message;

          this.isLoading = false;
          console.log(err);
        });
    },

    setTotal(value) {
      this.total = value;
    },
    addTotal(value) {
      this.total += value;
    },
    setCurrentKey(value) {
      this.current_key = value;
    },
  },
  computed: {
    getHeaders(){
      return this.headers;
    }
  },
  created() {
    this.getStatistique();
  },
};
</script>
